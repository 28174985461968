import axios from 'axios'
import Cookies from 'js-cookie'
import md5 from '@/libs/md5'

const key = '0x18bc95287894569328050a649c1e225f1f'
const LX = 'x'
const LS = 's'
const LT = 't'
const LI = 'i'
const LG = 'g'
const LN = 'n'
const LM = 'm'
const LE = 'e'

function randomString (len) {
  len = len || 32
  const $chars = 'abcdefhijkmnprstwxyz2345678'
  /** ** 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1 ****/
  const maxPos = $chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

const service = axios.create({
  baseURL: '/api',
  timeout: 30000
})

service.interceptors.request.use(
  (config) => {
    const t = new Date().getTime() + ''
    const arr = []
    if (config.method === 'get') {
      let a
      for (a in config.params) {
        if (config.params[a] !== null && config.params[a] !== '' && config.params[a] !== undefined) {
          arr.push(a + '=' + config.params[a])
        }
      }
    } else {
      let a
      for (a in config.data) {
        if (config.data[a] !== null && config.data[a] !== '' && config.data[a] !== undefined) {
          arr.push(a + '=' + config.data[a])
        }
      }
    }
    arr.sort()
    const sign = md5(t.substr(2, 12) + key + arr.join(''))
    config.headers[`${LX}-${LS}${LI}${LG}${LN}`] = randomString(12) + sign.substr(5, 25) + randomString(12) + '=='
    config.headers[`${LX}-${LT}${LI}${LM}${LE}`] = t
    config.headers['Cache-Control'] = 'no-cache'
    const csrfToken = Cookies.get('csrfToken')
    if (csrfToken) {
      config.headers['x-csrf-token'] = csrfToken
    }
    const jwtToken = localStorage.getItem('JP.DYK.Token')
    if (jwtToken) {
      config.headers['Authorization'] = 'Bearer ' + jwtToken
    }
    config.headers.Pragma = 'no-cache'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (res) => {
    return res.data
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.replace(`/film/login`)
    }
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
