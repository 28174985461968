<template>
  <section class="m-item">
    <div class="m-wrap">
      <div class="m-info">
        <div class="poster-c">
          <a class="poster" :href="`/film/show-detail?showId=${model.id}`">
            <img :data-src="model.pic" alt="" src="" />
          </a>
        </div>
        <a class="m-item-content" :href="`/film/show-detail?showId=${model.id}`">
          <div class="title">
            <div class="show-name">{{ model.name }}</div>
          </div>
          <div class="brief">
            观众评分&nbsp;<span class="txt-warning">{{ model.grade === '0' ? '暂无' : model.grade }}</span>
          </div>
          <div class="brief" v-if="model.director">导演：{{ model.director }}</div>
          <div class="brief" v-if="model.cast">主演：{{ model.cast }}</div>
        </a>
        <div class="btn-wrap"><a :href="`/film/show-cinema-list?showId=${model.id}`" class="buy-btn">购票</a></div>
      </div>
    </div>
  </section>
</template>

<script>
import * as pageUtil from '@/utils/pageUtil'

export default {
  name: 'MovieItem',
  props: {
    model: Object
  },
  computed: {
    imgFormat() {
      return pageUtil.checkSupportWebp() ? 'webp' : 'jpg'
    }
  }
}
</script>

<style lang="less" scoped>
.m-item {
  position: relative;
  background: #fff;
  padding-left: 4vw;
  margin-bottom: 2vw;
  border-radius: 6px;

  .m-wrap {
    background: transparent linear-gradient(0deg, #ededed, #ededed 50%, transparent 0, transparent 0) 0 100% repeat-x;
    background-size: 100% 1px;
  }

  .m-info {
    display: flex;
    padding: 4vw 4vw 4vw 0;
  }

  .poster-c {
    position: relative;
    background: #e2e2e2 no-repeat 50%;
    //background-image: url("../../img/icon/loading-icon-1.png");
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 17.3vw;
    height: 25.3vw;
    background-size: 8vw 8vw;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 5px;

    .poster {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      display: block;
      //background-repeat: no-repeat;
      //background-position: 50%;
      //background-size: cover;
      img {
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;

        &[lazy='loaded'] {
          opacity: 1;
          transition: opacity 0.2s ease-in 0s;
        }
      }
    }
  }

  .m-item-content {
    padding-left: 2.7vw;
    padding-right: 2.7vw;
    flex: 1 1 auto;
    overflow: hidden;

    .title {
      display: flex;
      align-items: center;
      overflow: hidden;
      max-width: 56.8vw;

      .show-name {
        color: #000;
        //font-weight: bold;
        flex: 0 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        max-width: 100%;
        font-size: 16px;
      }
    }

    .brief {
      color: #777;
      font-size: 12px;
      margin-top: 3px;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .txt-warning {
        color: #fea54c;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .btn-wrap {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .buy-btn {
      border: 0;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      color: #fff;
      background-color: transparent;
      box-sizing: border-box;
      height: 28px;
      line-height: 28px;
      padding: 0 3.5vw;
      font-size: 12px;
      border-radius: 14px;
      display: block;
      //background-image: -webkit-linear-gradient(45deg,#ff7ba0,#ff4d64);
      background-image: -webkit-linear-gradient(45deg, #4d8aff, #206CFE);

      &.pre {
        background-image: linear-gradient(45deg, #5ea7e5, #3387e5);
      }
    }
  }
}
</style>
