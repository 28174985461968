<template>
  <div class="city-c">
    <div class="current-city" @click="handleLocationClick">
      <div class="city-name">{{ selecctedCity.regionName }}</div>
      <div class="arrow-down"></div>
    </div>
    <choose-city v-model="chooseCityShow" :selectedCity="selecctedCity" @cityChange="handleCityChange" />
  </div>
  <van-tabs @change="handleTabChange" color="#206CFE" title-active-color="#206CFE" sticky>
    <van-tab title="正在热映" name="hot">
      <div v-if="hotShowList.length > 0" class="movie-list" v-lazy-container="{ selector: 'img' }" :style="{ height: listHeight }">
        <movie-item v-for="(item, index) in hotShowList" :key="index" :model="item" />
      </div>
      <van-empty v-else description="暂无热映电影信息" />
    </van-tab>
    <!--    <van-tab title="即将上映" name="soon">-->
    <!--      <div class="movie-list" v-lazy-container="{ selector: 'img' }">-->
    <!--        <movie-item v-for="(item, index) in soonShowList" :key="index" :model="item" />-->
    <!--      </div>-->
    <!--    </van-tab>-->
  </van-tabs>
  <tab-bar tab="dianying"></tab-bar>
</template>

<script>
import { Tab, Tabs, Dialog, Empty } from 'vant'
import * as pageUtil from '@/utils/pageUtil'
import request from '../utils/request'
import MovieItem from '../components/MovieItem.vue'
import ChooseCity from '../components/ChooseCity.vue'
import * as mapUtil from '@/utils/mapUtil'
import TabBar from '../components/TabBar'

export default {
  name: 'HomeIndex',
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    VanEmpty: Empty,
    TabBar,
    MovieItem,
    ChooseCity
  },
  data () {
    return {
      movieIcon: {
        inactive: '//img-1.yuangonglife.com/images/7f4e756350904a51b3d569026751b62e.png',
        active: '//img-1.yuangonglife.com/images/3fbb5320c6b74dd6b7f2b875f167aee1.png'
      },
      cinemaIcon: {
        inactive: '//img-1.yuangonglife.com/images/f34a584e6ae54e729ff66b2ac1104853.png',
        active: '//img-1.yuangonglife.com/images/8b863c851bf546d1b79d27d430162fbd.png'
      },
      active: 0,
      chooseCityShow: false,
      hotShowList: [],
      soonShowList: [],
      selecctedCity: {
        cityCode: '',
        id: '',
        pinYin: '',
        regionName: ''
      },
      listHeight: '10000px'
    }
  },
  async mounted () {
    document.title = '京票儿-电影'
    this.selecctedCity = (await mapUtil.getSelectedCityInfo()).data
    await this.getShowList()
    await mapUtil.getCurrentCityInfo()
  },
  methods: {
    handleLocationClick () {
      this.chooseCityShow = true
    },
    async getShowList () {
      const res = await request.get('/dyk/getShowsByCityCode', {
        params: {
          cityCode: this.selecctedCity.cityCode
        }
      })
      this.hotShowList = res.data
      this.$nextTick(() => {
        this.listHeight = 'auto'
        pageUtil.showPage()
      })
    },
    handleCityChange (item) {
      this.selecctedCity = item
      pageUtil.hidePage()
      this.getShowList()
    },
    async getSoonShowList () {
      const res = await request.get('/dyk/getSoonShowsByCityCode')
      this.soonShowList = res.data
    },
    handleTabChange (name) {
      if (name === 'soon' && this.soonShowList.length === 0) {
        this.getSoonShowList()
      }
    }
  }
}
</script>

<style lang="less">
.root {
  padding-bottom: 55px;
}

.movie-list {
  padding: 0 2vw;
  margin-top: 2vw;
}

.van-tabs__wrap {
  position: relative;

  .van-tabs__nav {
    //width: 80%;
    //float: right;
  }

  .van-tab--active {
    font-weight: 700;
    color: #ff5456;
  }
}

.city-c {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  height: 44px;
  line-height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 100;

  .current-city {
    padding-left: 4vw;
    position: relative;
  }

  .city-name {
    color: #333;
    font-weight: 700;
    font-size: 0.9375rem;
    max-width: 18.7vw;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .arrow-down {
    margin-left: 3px;
    width: 8px;
    height: 4px;
    position: relative;

    &:after {
      content: '';
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #333;
      display: block;
      position: absolute;
      margin: -1px 0 0 -4px;
    }
  }

  .arrow-down,
  .city-name {
    vertical-align: middle;
    display: inline-block;
  }
}
</style>
