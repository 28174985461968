<template>
  <div class="cinema-search-container" v-show="modelValue">
    <div class="cinema-search-hd">
      <a href="javascript:" class="cinema-search-cancel" @click="handleCancel">取消</a>
      <div class="cinema-search-control">
        <i class="icon-mod icon-search icon-size-14"></i>
        <i class="icon-mod icon-clear icon-size-14 fn-hide"></i>
        <input type="text" placeholder="影院名称" v-model="keyword" />
      </div>
    </div>
    <div class="cinema-search-mod" id="cinemaSearchMod">
      <div class="cinema-search-bd">
        <div class="cinema-list">
          <a
            class="list-item"
            v-for="(item, index) in cinemaList"
            :key="index"
            :href="`/film/show-list?cinemaId=${item.id}&showId=${showId || ''}&startDay=${startDay || ''}`"
          >
            <div class="list-item-in">
              <div class="list-title-warper">
                <div class="list-title-left">
                  <span class="list-title" v-html="item.cinemaName"></span>
                </div>
              </div>
              <div class="list-address">
                <span class="list-distance">{{ item.distance }}km </span>
                <div class="list-location" v-html="item.cinemaAddr"></div>
                <div class="list-search-distance"></div>
              </div>
            </div>
          </a>
        </div>
        <div class="cinema-list cinema-search-result">
          <ul id="cinemaSearchList" v-if="loading === false && cinemaList.length === 0 && isRequest">
            <li>
              <div class="tc" style="padding-top: 20%; color: #999">亲，没有找到合适的影院...</div>
            </li>
          </ul>
          <div class="search-load-more fn-hide" v-if="loading">加载中...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as pageUtil from '@/utils/pageUtil'
import request from '../utils/request'

export default {
  name: 'CinemaSearch',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    cityCode: String,
    showId: String,
    startDay: String,
    coords: Object
  },
  data() {
    return {
      keyword: '',
      cinemaList: [],
      loading: false,
      isRequest: false,
      pageSize: 1000,
      pageIndex: 1
    }
  },
  methods: {
    handleCancel() {
      this.$emit('update:modelValue', false)
      pageUtil.autoScroll()
    },
    async getCinemaList() {
      this.loading = true
      this.isRequest = true
      let params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        cityCode: this.cityCode,
        showId: this.showId,
        startDay: this.startDay
      }
      if (this.coords.latitude) {
        params.latitude = this.coords.latitude
        params.longitude = this.coords.longitude
      }
      if (this.keyword) {
        params.keyword = this.keyword
      }
      let res = await request('/dyk/getCinemaListInPage', {
        params: params
      })
      let list = res.data.list
      list.forEach((item) => {
        if (item.distance > 100000) {
          item.distance = '>100'
        } else {
          if (item.distance) {
            item.distance = (item.distance / 1000).toFixed(1)
          } else {
            item.distance = '-'
          }
        }
        item.cinemaName = item.cinemaName.replace(this.keyword, `<span class="color-red">${this.keyword}</span>`)
        item.cinemaAddr = item.cinemaAddr.replace(this.keyword, `<span class="color-red">${this.keyword}</span>`)
      })
      this.cinemaList = list
      this.loading = false
    }
  },
  watch: {
    modelValue(newValue) {
      if (newValue) {
        pageUtil.noScroll()
      } else {
        this.keyword = ''
      }
    },
    keyword(newValue) {
      if (newValue) {
        this.getCinemaList().then()
        console.log(newValue)
      } else {
        this.cinemaList = []
        this.isRequest = false
      }
    }
  }
}
</script>

<style scoped lang="less">
a {
  color: #00afff;
  text-decoration: none;
}

.icon-mod {
  width: 18px;
  height: 18px;
  display: inline-block;
  text-align: center;
  background-size: 18px 18px;
}

.icon-mod.icon-size-14 {
  width: 14px;
  height: 14px;
  background-size: 14px 14px;
  line-height: 14px;
}

.cinema-search-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.cinema-search-hd {
  z-index: 99;
  padding: 7px 4vw;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  background-color: #fff;
}

.cinema-search-hd:after {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 1px;
  background: #ededed;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: 100% 100%;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx), (min-resolution: 144dpi) {
  .cinema-search-hd:after {
    transform: scaleY(0.5);
  }
}

@media (-webkit-device-pixel-ratio: 1.5) {
  .cinema-search-hd:after {
    transform: scaleY(0.6666);
  }
}

@media (-webkit-device-pixel-ratio: 3) {
  .cinema-search-hd:after {
    transform: scaleY(0.3333);
  }
}

.cinema-search-hd > .cinema-search-cancel {
  float: right;
  padding-left: 4vw;
  font-size: 15px;
  line-height: 32px;
}

.cinema-search-hd .cinema-search-control {
  background: #f5f5f5;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}

.cinema-search-hd .cinema-search-control > input[type='text'] {
  width: 100%;
  line-height: 160%;
  border: none;
  font-size: 0.8125rem;
  height: 32px;
  background-color: transparent;
}

.cinema-search-hd .cinema-search-control .icon-search {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  left: 10px;
}

.cinema-search-hd .cinema-search-control .icon-clear {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 10px;
}

.cinema-search-mod {
  flex: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: hsla(0, 0%, 100%, 1);
  padding-top: 46px;
}

.cinema-search-mod .cinema-search-favorite {
  display: none;
  padding: 6%;
}

.cinema-search-mod .cinema-search-favorite > p {
  font-size: 13px;
  color: #abd6ca;
}

.color-red {
  color: #eb002a;
}

.load-more,
.search-load-more {
  font-size: 14px;
  padding: 2.93333333vw 0;
  text-align: center;
  opacity: 0.8;
}

.tc {
  text-align: center;
}
</style>
