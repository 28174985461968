<template>
  <section class="tab-bar">
    <a :class="{ active: tab === 'dianying' }" href="/film/index">
      <i class="iconfont icon-dianying"></i>
      <span>热映</span>
    </a>
    <a :class="{ active: tab === 'yingyuan' }" href="/film/cinema-list">
      <i class="iconfont icon-yingyuan"></i>
      <span>影院</span>
    </a>
    <a :class="{ active: tab === 'wode' }" href="/film/my">
      <i class="iconfont icon-wode"></i>
      <span>我的</span>
    </a>
  </section>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
    tab: String
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
@import '../../../fonts/iconfont.css';

.tab-bar {
  z-index: 200;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-size: 100% 1px;
  background: #ffffff linear-gradient(to top, #ededed, #ededed 50%, transparent 0, transparent 0) repeat-x 0 0;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  height: 50px;
  padding-top: 1vw;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  a {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: #666;
    position: relative;
    z-index: 10;
    background-color: #ffffff;

    //span {
    //  line-height: 5vw;
    //}

    i {
      font-size: 5vw;
      display: block;
    }

    &.active {
      color: #206CFE;
    }
  }
}
</style>
